import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { api } from '~/api'
import { cacheKeys } from '~/api/cacheKeys'
import { toastServerError } from '~/utils/error'
import { manaberuPlan } from '~/utils/manaberuPlan'
import { useGetStore } from './useGetStore'
import { useGetUserCourse } from './useGetUserCourse'
import { useMe } from './useMe'

export type CreatedCourses = {
  id: number
  storeId: number
  title: string
  thumbnail: string
  count: number
  price: number
  subdomain?: string
}[]

export type PurchasedCourses = {
  id: number
  title: string
  thumbnail: string
  description: string
  subdomain?: string
}[]

export const useGetCourses = () => {
  const { data: userData, isLoading: isLoadingMe } = useMe()
  const { data: storeData, isLoading: isLoadingGetStore } = useGetStore()
  const { data: purchasedData, isLoading: isLoadingUserCourseAccess } = useQuery(
    cacheKeys.dGetUserCourseAccess,
    () => api.dashboard.dGetUserCourseAccess(),
    {
      onError(err) {
        toastServerError(err)
      },
    }
  )
  const { data: createdData, isLoading: isLoadingGetUserCourse } = useGetUserCourse({
    storeId: storeData?.data.id ?? NaN,
    options: {
      enabled: storeData?.data.id !== undefined,
    },
  })

  useQuery(
    cacheKeys.dGetUserCourse,
    () => {
      if (!storeData?.data) return
      return api.dashboard.dGetUserCourse(storeData.data.id)
    },
    {
      enabled: storeData !== undefined,
      onError(err) {
        toastServerError(err)
      },
    }
  )

  const purchasedCourses: PurchasedCourses = useMemo(
    () =>
      purchasedData?.data.map((course) => ({
        id: course.id,
        title: course.title,
        thumbnail: course.thumbnail_image_url,
        description: course.description,
        subdomain: course.store?.subdomain,
      })) || [],
    [purchasedData]
  )

  const createdCourses: CreatedCourses = useMemo(
    () =>
      createdData?.data.map((course) => ({
        id: course.id,
        title: course.title,
        count: course.student_count,
        thumbnail: course.thumbnail_image_url,
        price: course.price,
        storeId: storeData?.data.id || NaN,
        subdomain: storeData?.data.subdomain ?? undefined,
      })) || [],
    [createdData, storeData]
  )

  const isLimited = useMemo(() => {
    if (!userData?.data) return true

    switch (userData.data.plan) {
      case 'free':
        return createdCourses.length >= manaberuPlan.free.maxLimit
      case 'standard':
        return createdCourses.length >= manaberuPlan.standard.maxLimit
      case 'pro':
        return createdCourses.length >= manaberuPlan.professional.maxLimit
      default:
        return true
    }
  }, [userData, createdCourses])

  return {
    purchasedCourses,
    createdCourses,
    isLimited,
    isLoading:
      isLoadingMe || isLoadingGetStore || isLoadingUserCourseAccess || isLoadingGetUserCourse,
  }
}
