import { css, cx } from '@linaria/core';
import { mdiDotsVertical } from '@mdi/js';
import Image from 'next/image';
import Link from 'next/link';
import React, { ButtonHTMLAttributes, memo, ReactNode, VFC } from 'react';
import { Icon } from '~/components/elements/Icon';
import { theme } from '~/style';
type Props = {
  href?: string;
  thumbnail: string;
  title: string;
  children: ReactNode;
  onClickDots?: () => void;
};

const CourseCardBase: VFC<Props> = ({
  href,
  thumbnail,
  title,
  children,
  onClickDots
}) => <article className={base}>
    {href && <div className={cover}>
        <Link passHref href={href}>
          <a className={anchor} />
        </Link>
      </div>}
    <div className={thumbnailContainer}>
      <Image className={img} src={thumbnail} layout="fill" alt="タイトル" />
    </div>
    <div className={inner}>
      <h1 className={titleText}>{title}</h1>

      <footer className={footer}>{children}</footer>
    </div>

    {onClickDots && <div className={buttonWrapper}>
        <DotsButton onClick={onClickDots} />
      </div>}
  </article>;

type DotsButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const DotsButton: React.VFC<DotsButtonProps> = ({
  className,
  ...props
}) => <button className={cx(dotsButton, className)} {...props}>
    <Icon path={mdiDotsVertical} size={20} />
  </button>;

export default memo(CourseCardBase);
const width = 440;
const thumbnailHeight = 220;
const base = "by58r44";
const cover = "c15j6xam";
const anchor = "a6ts19b";
const thumbnailContainer = "tt2dhf2";
const img = "ipwuem8";
const inner = "i1emoad3";
const titleText = "t2rebi0";
const footer = "ftyz4rs";
const dotsButton = "d1jfjpub";
const buttonWrapper = "bk4k715";

require("./CourseCardBase.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./CourseCardBase.tsx");