import { css } from '@linaria/core';
import { ComponentProps, memo, VFC } from 'react';
import { PurchasedCourses } from '~/hooks/useGetCourses';
import { manaberuLink } from '~/utils/links';
import CourseCardBase from '../CourseCardBase';
export type Props = Omit<ComponentProps<typeof CourseCardBase>, 'children' | 'onClickDots'> & PurchasedCourses[number];

const PurchasedCourseCard: VFC<Props> = ({
  description,
  ...props
}) => {
  // const [isOpen, onClose, onOpen] = useDisclosure()
  // const onCopyLink: MouseEventHandler<HTMLButtonElement> = () => {
  //   // TODO: アフィリエイトリンクをコピー
  //   console.log(`id${props.id}の講座のアフィリエイトリンクをコピー`)
  //   onClose()
  // }
  return <CourseCardBase {...props} href={props.subdomain ? manaberuLink.store(props.subdomain) : undefined} // onClickDots={onOpen}
  >
      <p className={text}>{description}</p>

      {
      /* <div className={menuWrapper}>
       <PopupMenu isOpen={isOpen} onClose={onClose}>
         <MenuItemButton onClick={onCopyLink}>アフィリエイトリンクをコピー</MenuItemButton>
       </PopupMenu>
      </div> */
    }
    </CourseCardBase>;
};

export default memo(PurchasedCourseCard);
const text = "tgml1sr"; // CourseCardBaseのbuttonWrapperと同じz-indexを設定
// const menuWrapper = css`
//   position: absolute;
//   z-index: 99;
//   bottom: -50px;
//   right: 0px;
// `

require("./PurchasedCourseCard.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./PurchasedCourseCard.tsx");