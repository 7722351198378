import NextHead from 'next/head'
import { VFC } from 'react'

type Props = {
  title?: string
}

export const Head: VFC<Props> = (props) => {
  const title = props.title ? `${props.title} - マナベル` : 'マナベル'

  return (
    <NextHead>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </NextHead>
  )
}
