import { css, cx } from '@linaria/core';
import Link from 'next/link';
import React, { ButtonHTMLAttributes, ComponentProps, ReactNode, VFC } from 'react';
import { theme } from '~/style';
import { color } from '~/style/color';
type MenuItemAnchorProps = ComponentProps<typeof Link> & {
  children: ReactNode;
};
type MenuItemButton = ButtonHTMLAttributes<HTMLButtonElement>;
export const MenuItemAnchor: VFC<MenuItemAnchorProps> = ({
  children,
  ...props
}) => {
  return <li className={item}>
      <Link {...props}>
        <a className={inner}>{children}</a>
      </Link>
    </li>;
};
export const MenuItemButton: VFC<MenuItemButton> = ({
  children,
  ...props
}) => {
  return <li className={item}>
      <button type="button" className={cx(inner, button)} {...props}>
        {children}
      </button>
    </li>;
};
const item = "i9glzs2";
const inner = "iqmqz0w";
const button = "bhift71";

require("./MenuItem.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./MenuItem.tsx");