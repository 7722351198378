import { css } from '@linaria/core';
import { memo, ReactNode, useEffect, useRef, VFC } from 'react';
import { theme } from '~/style';
import { color } from '~/style/color';
export type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const PopupMenu: VFC<Props> = ({
  onClose,
  isOpen,
  children
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const isOpenRef = useRef<boolean | null>(null);
  isOpenRef.current = isOpen;

  const onClickOutOfComponent = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains((event.target as Node)) && isOpenRef) onClose();
  };

  const onKeyDownEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isOpenRef) onClose();
  };

  useEffect(() => {
    ;

    (async () => {
      document.addEventListener('click', onClickOutOfComponent);
      document.addEventListener('keydown', onKeyDownEscape);
    })();

    return () => {
      document.removeEventListener('click', onClickOutOfComponent);
      document.removeEventListener('keydown', onKeyDownEscape);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isOpen ? <ul className={container} ref={ref}>
      {children}
    </ul> : null;
};

export default memo(PopupMenu);
const container = "ch7d9sk";

require("./PopupMenu.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./PopupMenu.tsx");