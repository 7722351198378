import { useQuery } from 'react-query'
import { api } from '~/api'
import { cacheKeys } from '~/api/cacheKeys'
import { toastServerError } from '~/utils/error'

type Props = {
  storeId: number
  options?: {
    enabled?: boolean
  }
}

export const useGetUserCourse = ({ storeId, options }: Props) => {
  return useQuery(cacheKeys.dGetUserCourse, () => api.dashboard.dGetUserCourse(storeId), {
    onError(err) {
      toastServerError(err)
    },
    ...options,
  })
}
