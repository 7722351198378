import { Plan } from '~/api/generated'

export const manaberuPlan = {
  free: { plan: Plan.Free, name: 'フリープラン', price: '月額無料', rawPrice: 0, maxLimit: 3 },
  standard: {
    plan: Plan.Standard,
    name: 'スタンダードプラン',
    price: '月額9,800円',
    rawPrice: 9800,
    maxLimit: 5,
  },
  professional: {
    plan: Plan.Pro,
    name: 'プロフェッショナルプラン',
    price: '月額19,800円',
    rawPrice: 19800,
    maxLimit: 10,
  },
} as const
