import { css } from '@linaria/core';
import { mdiAccountMultiple } from '@mdi/js';
import React, { ComponentProps, memo, VFC } from 'react';
import { Icon } from '~/components/elements/Icon';
import { useDisclosure } from '~/components/elements/Modal/useModal';
import { PopupMenu } from '~/components/elements/PopupMenu';
import { MenuItemAnchor } from '~/components/elements/PopupMenu/MenuItem/MenuItem';
import { CreatedCourses } from '~/hooks/useGetCourses';
import { manaberuLink } from '~/utils/links';
import CourseCardBase from '../CourseCardBase';
export type Props = Omit<ComponentProps<typeof CourseCardBase>, 'children' | 'onClickDots'> & CreatedCourses[number];

const CreatedCourseCard: VFC<Props> = ({
  count,
  price,
  ...props
}) => {
  const [isOpen, onClose, onOpen] = useDisclosure();
  return <CourseCardBase {...props} href={manaberuLink.editCourse({
    storeId: props.storeId,
    courseId: props.id
  })} onClickDots={props.subdomain ? onOpen : undefined}>
      <div className={footer}>
        <div>{price.toLocaleString()}円</div>
        <div className={flexBox}>
          <Icon path={mdiAccountMultiple} size={14} />
          {count.toLocaleString()}人
        </div>
      </div>

      {props.subdomain && <div className={menuWrapper}>
          <PopupMenu isOpen={isOpen} onClose={onClose}>
            <MenuItemAnchor href={manaberuLink.store(props.subdomain)}>ストア</MenuItemAnchor>
          </PopupMenu>
        </div>}
    </CourseCardBase>;
};

export default memo(CreatedCourseCard);
const flexBox = "fky3jfz";
const footer = "fbtfekc"; // CourseCardBaseのbuttonWrapperと同じz-indexを設定

const menuWrapper = "m1mqfupu";

require("./CreatedCourseCard.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./CreatedCourseCard.tsx");