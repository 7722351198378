import { MouseEvent, useCallback, useState } from 'react'

export type UseDisclosureReturns = readonly [boolean, () => void, (e?: MouseEvent) => void]

export const useDisclosure = (): UseDisclosureReturns => {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onOpen = useCallback((e?: MouseEvent) => {
    setIsOpen(true)
    e?.stopPropagation()
  }, [])

  return [isOpen, onClose, onOpen] as const
}
